import { graphql } from "gatsby";
import BlogPage from "../../components/pages/blog";

export default BlogPage;

export const query = graphql`
  query BlogQuery {
    articles: allStoryblokEntry(
      filter: { field_component: { eq: "blog" }, lang: { eq: "default" } }
    ) {
      nodes {
        name
        content
        tag_list
      }
    }
  }
`;
